import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: "/home",
    component: () => import('../views/index.vue'),
    children: [
      {
        path: "/home",
        component: () => import('../views/home/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/memberAdmin",
        component: () => import('../views/memberAdmin/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/viceCardAdmin",
        component: () => import('../views/viceCardAdmin/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/staff",
        component: () => import('../views/staff/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/product",
        component: () => import('../views/product/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/workAdmin",
        component: () => import('../views/workAdmin/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/cashier",
        component: () => import('../views/cashier/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/totalCashier",
        component: () => import('../views/totalCashier/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/creditsExchange",
        component: () => import('../views/creditsExchange/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
      
      {
        path: "/record",
        component: () => import('../views/record/index.vue'),
        meta: {
          requireAuth: true,
        },
      },
    ]
  },
  {
    path: "/login",
    name: 'login',
    component: () => import('../views/login/index.vue'),
  },
  {
    path: "/register",
    name: 'register',
    component: () => import('../views/register/index.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const tokenStr = window.sessionStorage.getItem('token')
  //获取token
  if (to.meta.requireAuth) {
    if (tokenStr) {
      next();
    } else {
      console.log("/login");
      next({
        path: "/login"
      });
    }
  } else {
    if (to.path === '/login' && tokenStr) {
      next({
        path: '/'
      })
    } else {
      next()
    }

  }
})

export default router
