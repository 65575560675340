import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './icons'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.scss'
import * as echarts from 'echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import axios from "axios";
import * as filters from './filters'
Vue.config.productionTip = false
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts
Vue.prototype.$role = window.sessionStorage.getItem("status");
new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    Bus: new Vue()
}
}).$mount('#app')
